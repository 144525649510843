.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-box {
  width: 350px;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.login-box h2 {
  text-align: center;
  margin-bottom: 24px;
  color: #333;
}

.login-form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.login-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #3367d6;
}

.login-button:disabled {
  background-color: #a4a4a4;
  cursor: not-allowed;
}

.divider {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.divider:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}

.divider:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 45%;
  height: 1px;
  background-color: #ddd;
}

.divider span {
  display: inline-block;
  padding: 0 10px;
  background-color: white;
  position: relative;
  z-index: 1;
  color: #777;
}

.google-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
  border-left: 4px solid #d32f2f;
} 